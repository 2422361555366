import { format, isSameDay } from 'date-fns';

import { EVENT_TYPE_IN_PERSON, EVENT_TYPE_LIVE } from '../constants';
import { t } from '../localization';

export const getEventProminentUrlProps = ({ eventInfo }) => {
  const EVENT_LOCATION_TEXT = {
    LIVE: t('online'),
    IN_PERSON: t('in-person')
  };

  const isRegistered = eventInfo?.registered;

  if (eventInfo.type === EVENT_TYPE_LIVE) {
    const locationText = isRegistered
      ? (eventInfo?.liveLink ?? EVENT_LOCATION_TEXT.LIVE)
      : EVENT_LOCATION_TEXT.LIVE;
    return {
      type: eventInfo.type,
      text: locationText.trim(),
      showCopyIcon: locationText !== EVENT_LOCATION_TEXT.LIVE
    };
  }

  if (eventInfo.type === EVENT_TYPE_IN_PERSON) {
    const locationText = isRegistered
      ? (eventInfo?.inPersonLocation ?? EVENT_LOCATION_TEXT.IN_PERSON)
      : EVENT_LOCATION_TEXT.IN_PERSON;
    return {
      type: eventInfo.type,
      text: locationText.trim(),
      showCopyIcon: locationText !== EVENT_LOCATION_TEXT.IN_PERSON
    };
  }

  return {};
};

export const getFormattedEventStartDate = (event) => {
  try {
    return format(new Date(event.startTime), 'EEEE, d MMMM');
  } catch (e) {
    return '';
  }
};

/**
 * Returns the formatted start and end time range of an event.
 * If the start and end time are on the same day, the format will be 'h:mma - h:mma'.
 * If the start and end time are on different days, the format will be 'h:mma - d MMM, h:mma'.
 *
 * @param {Object} event - The event object.
 * @returns {string} The formatted start and end time range.
 * @example
 * const event = {
 *   startTime: '2022-01-01T10:00:00',
 *   endTime: '2022-01-01T12:00:00'
 * };
 * const timeRange = getEventStartAndEndTimeRange(event);
 * console.log(timeRange); // Output: '10:00AM - 12:00PM'
 */
export const getEventStartAndEndTimeRange = (event) => {
  try {
    const startTime = new Date(event.startTime);
    const endTime = new Date(event.endTime);
    if (isSameDay(startTime, endTime)) {
      const formattedStart = format(startTime, 'h:mma');
      const formattedEnd = format(endTime, 'h:mma');
      return `${formattedStart} - ${formattedEnd}`;
    } else {
      const formattedStart = format(startTime, 'h:mma');
      const formattedEnd = format(endTime, 'd MMM, h:mma');
      return `${formattedStart} - ${formattedEnd}`;
    }
  } catch (e) {
    return '';
  }
};

export const checkStrongPassword = (password) => {
  const strongRegex = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'
  );
  return strongRegex.test(password);
};

export const hasBase64Content = (str) => {
  const base64Regex =
    /data:image\/(png|jpg|jpeg|gif|webp|heic|heif|avif);base64,/g;

  return base64Regex.test(JSON.stringify(str));
};

export const getFormattedUserName = (user) => {
  if (!user?.firstName && !user?.lastName) return 'Community Member';

  if (user?.firstName && !user?.lastName) return user?.firstName;

  if (!user?.firstName && user?.lastName) return user?.lastName;

  return `${user?.firstName} ${user?.lastName}`;
};

export const scrollToViewportWithOffset = ({ element }) => {
  try {
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition - 264; // offset by navbar

    window.scrollBy({
      top: offsetPosition,
      behavior: 'smooth'
    });
  } catch {
    console.error('Invalid params passed to scrollToViewportWithOffset');
  }
};
